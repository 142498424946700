<script>
import LinkForm from "@/views/links/linkForm";
import Component, { mixins } from "vue-class-component";
import { Utility } from "@/lib/Utility";

@Component({
  inject: {
    standService: "standService",
    eventService: "eventService",
    fileUploadService: "fileUploadService",
    toastService: "toastService",
  },
})
export default class LinkEdit extends mixins(LinkForm) {
  formId = "edit-link-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        disabled: () => {
          return this.fileUploadService.isUploading();
        },
        handle: this.save,
      },
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      const requestModel = Utility.clone(this.model);
      requestModel.x = +this.model.x;
      requestModel.y = +this.model.y;
      requestModel.popupWidth = +this.model.popupWidth;
      requestModel.popupHeight = +this.model.popupHeight;
      requestModel.popupX = +this.model.popupX;
      requestModel.popupY = +this.model.popupY;
      requestModel.height = +this.model.height;
      requestModel.width = +this.model.width;
      requestModel.localizations = [
        ...this.model.localizations,
        ...this.model.hotspotLocalizations,
      ];

      requestModel.hotspotOptions = this.prepareHotspotOptions();

      await this.linkService.update(this.model.id, requestModel);
      this.$router.push(`/link/${this.model.from}/${this.$route.params.entityType}`);
      this.toastService.success(this.translations.success.link_edit);
    } catch (error) {
      this.model.localizations = [];
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.link_edit;
  }

  async afterCreate() {
    this.model = await this.linkService.read(this.$route.params.id);
    this.model.localizations = [];

    await this.refreshToItems();
    this.isReady = true;
  }
}
</script>
